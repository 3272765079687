<template>
  <a-card title="广告数据" :bordered="false" class="advs-panel">
    <a-row>
      <a-col :span="4">
        <div>
          <p>展示次数</p>
          <a-typography-title>600</a-typography-title>
        </div>
      </a-col>
      <a-col :span="4">
        <div>
          <p>点击数</p>
          <a-typography-title>256</a-typography-title>
        </div>
      </a-col>
    </a-row>
  </a-card>
  <a-card title="数据趋势" :bordered="false">
    <div id="container" />
  </a-card>
</template>
<script>
import { defineComponent, onMounted } from "vue";
// import * as Api from "@/views/advs/api";
// import { message, Modal } from "ant-design-vue";
// import { useRouter } from "vue-router";
import { Chart } from "@antv/g2";

export default defineComponent({
  setup() {
    // const router = useRouter();

    // 初始化
    onMounted(() => {
      initShowChart();
    });

    const initShowChart = () => {
      // 默认已经加载 legend-filter 交互
      const data = [
        { month: "Jan", city: "展示数", temperature: 7 },
        { month: "Jan", city: "点击数", temperature: 3.9 },
        { month: "Feb", city: "展示数", temperature: 6.9 },
        { month: "Feb", city: "点击数", temperature: 4.2 },
        { month: "Mar", city: "展示数", temperature: 9.5 },
        { month: "Mar", city: "点击数", temperature: 5.7 },
        { month: "Apr", city: "展示数", temperature: 14.5 },
        { month: "Apr", city: "点击数", temperature: 8.5 },
        { month: "May", city: "展示数", temperature: 18.4 },
        { month: "May", city: "点击数", temperature: 11.9 },
        { month: "Jun", city: "展示数", temperature: 21.5 },
        { month: "Jun", city: "点击数", temperature: 15.2 },
        { month: "Jul", city: "展示数", temperature: 25.2 },
        { month: "Jul", city: "点击数", temperature: 17 },
        { month: "Aug", city: "展示数", temperature: 26.5 },
        { month: "Aug", city: "点击数", temperature: 16.6 },
        { month: "Sep", city: "展示数", temperature: 23.3 },
        { month: "Sep", city: "点击数", temperature: 14.2 },
        { month: "Oct", city: "展示数", temperature: 18.3 },
        { month: "Oct", city: "点击数", temperature: 10.3 },
        { month: "Nov", city: "展示数", temperature: 13.9 },
        { month: "Nov", city: "点击数", temperature: 6.6 },
        { month: "Dec", city: "展示数", temperature: 9.6 },
        { month: "Dec", city: "点击数", temperature: 4.8 },
      ];

      const chart = new Chart({
        container: "container",
        autoFit: true,
        height: 500,
      });

      chart.data(data);
      chart.scale({
        month: {
          range: [0, 1],
        },
        temperature: {
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      chart.axis("temperature", {
        label: {
          formatter: (val) => {
            return val + " °C";
          },
        },
      });

      chart.line().position("month*temperature").color("city").shape("smooth");

      chart
        .point()
        .position("month*temperature")
        .color("city")
        .shape("circle")
        .style({
          stroke: "#fff",
          lineWidth: 1,
        });

      chart.render();
    };
    return { initShowChart };
  },
});
</script>
<style scoped lang="less">
.advs-panel {
  margin-bottom: 20px;
}
</style>